const initFbEvent = () => {
    const socialMedia = window.settings.socialMedia
    const facebook = socialMedia && socialMedia.facebook 
    const pixelId = facebook && facebook.pixelId
    const facebookAdditionalPixelIdsInString = facebook && facebook.facebookAdditionalPixelIds
    const facebookAdditionalPixelIdsInArray = (facebook && facebook.facebookAdditionalPixelIds && facebook.facebookAdditionalPixelIds.split(',')) || []

    if (pixelId || (facebookAdditionalPixelIdsInArray.length > 0)) {
        (function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function () {
                n.callMethod ?
                    n.callMethod.apply(n, arguments) : n.queue.push(arguments)
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = '2.0';
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s)
        })(window,
            document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
        // Insert your pixel ID here.
       if(pixelId){
        window.fbq('init', pixelId);
       }


            // intializing multiple pixel id 
        if (facebookAdditionalPixelIdsInArray && facebookAdditionalPixelIdsInArray.length > 0) {
            facebookAdditionalPixelIdsInArray.forEach(id => {
                window.fbq('init', id);
            });
        }
        
        
    }
}
export default initFbEvent;